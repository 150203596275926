<template>
  <div class="page-sub-box">
    <div class="locker_setting_list sub_new_style01 sub_ui_box1">
      <Search
        codeKey="search_history_task_type"
        :searchObj="searchType.obj"
        :useKeywordType="true"
        @change-search-type="$_changeSearchType"
        @onSearchClick="e => $_searchData(e, 'search_history_task_type')"
      >
        <template v-slot:before>
          <div class="ui-datepicker-item">
            <DxDateBox
              :edit-enabled="false"
              :styling-mode="stylingMode"
              :width="120"
              v-model="searchType.customTypes.dayStart"
              type="date"
              display-format="yyyy.MM.dd"
              @value-changed="onChangeSearchDay"
              dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
            >
            </DxDateBox>
          </div>
          <div class="ui-datepicker period">
            <span class="dash">~</span>
          </div>
          <div class="ui-datepicker-item">
            <DxDateBox
              :styling-mode="stylingMode"
              :width="120"
              v-model="searchType.customTypes.dayEnd"
              type="date"
              display-format="yyyy.MM.dd"
              @value-changed="onChangeSearchDay"
              dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
            >
            </DxDateBox>
          </div>
          <DxSelectBox
            v-model="searchType.customTypes.battype"
            placeholder="배치타입 선택"
            :items="getBatchType"
            display-expr="codeNm"
            value-expr="codeValue"
            :styling-mode="stylingMode"
            :width="120"
            :height="30"
            @value-changed="$_changeSearchCustomType('battype', $event)"
          />
        </template>
      </Search>
    </div>

    <esp-dx-data-grid :data-grid="dataGrid" ref="infoPushHistoryGrid" />
  </div>
</template>

<script>
  import Search from '@/components/common/search.vue';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { isSuccess, getPastFromToday } from '@/plugins/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
      DxSelectBox,
      DxDateBox,
      Search,
    },
    computed: {
      /** @description: U_CODE svr_type 가져오기 */
      getBatchType() {
        return [{ codeValue: null, codeNm: '전체' }, ...this.$_getCode('batch_type')];
      },
    },
    data() {
      return {
        infoPushSiteList: [],
        config: {
          pageSetting: {
            //pageData pageSetting 관련
            config: {},
          },
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            dayStart: getPastFromToday(30, 'days'),
            dayEnd: getPastFromToday(0, 'days'),
            regDt: null,
            battype: null,
          },
          paramsData: null,
        },
        dataGrid: {
          keyExpr: 'jobId',
          refName: 'infoPushHistoryGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          height: '650', // 주석처리시 100%
          apiActionNm: {
            update: 'EUC_TASK_HISTORY_UPDATE',
            delete: 'EUC_TASK_HISTORY_DELETE',
          },
          customEvent: {},
          showActionButtons: {
            delete: true,
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: true,
            sorting: true,
            grouping: true,
            paging: true,
          },
          paging: {
            enabled: true,
            pageSize: 100,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: 'CTI 타입',
              dataField: 'ctiType',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '사이트',
              dataField: 'site',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: 'Tenant',
              dataField: 'tenant',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: 'TASK명',
              dataField: 'taskNm',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '배치타입',
              dataField: 'battype',
              width: 130,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateCellValue: this.batchType,
            },
            {
              caption: '요청일시',
              dataField: 'ymd',
              width: 200,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              cellTemplate: (container, options) => {
                const batType = options.data?.battype;
                let result = '';
                if (batType === 'REALTIME' && options.data.timeontask !== '0') {
                  result = this.formatDt(options.data.ymd + options.data.hh24mi);
                } else {
                  result = this.$_commonlib.moment(options.data.ymd).format('YYYY-MM-DD 00:00:00');
                }
                container.append(result);
              },
            },
            {
              caption: '처리결과',
              dataField: 'result',
              width: 130,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '등록일',
              dataField: 'regDt',
              width: 200,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              calculateCellValue: e => this.formatDt(e.regDt),
            },
            {
              caption: '수행시각',
              dataField: 'timeontask',
              width: 130,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateCellValue: this.formatTaskDt,
              cellTemplate: (container, options) => {
                const timeOnTask = options.data?.timeontask || 0;
                let result = '';

                if (timeOnTask === 0) {
                  result = '1초 미만';
                } else {
                  const duration = this.$_commonlib.moment.duration(timeOnTask, 'seconds');
                  if (duration.hours() > 0) {
                    result = `${duration.hours()}시간`;
                  }
                  if (duration.minutes() > 0) {
                    result = result != '' ? `${result} ${duration.minutes()}분` : `${duration.minutes()}분`;
                  }
                  if (duration.seconds() > 0) {
                    result = result != '' ? `${result} ${duration.seconds()}초` : `${duration.seconds()}초`;
                  }
                }
                container.append(result);
              },
            },
            {
              caption: '완료시각',
              dataField: 'cmpltDt',
              width: 200,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateCellValue: e => this.formatDt(e.cmpltDt),
            },
            {
              caption: '비고',
              dataField: 'description',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
          ],
        },
      };
    },
    methods: {
      onChangeSearchDay() {
        const dayStart = this.$_commonlib.moment(this.searchType.customTypes.dayStart);
        const dayEnd = this.$_commonlib.moment(this.searchType.customTypes.dayEnd);
        const regDt = dayStart.format('YYYYMMDD000000') + '~' + dayEnd.format('YYYYMMDD235959');
        this.searchType.paramsData = { ...this.searchType.paramsData, regDt };

        this.$_setSearchHistsCustomType(this.searchType.paramsData);
      },
      /** @description : 라이프사이클 computed에서 this data를 변경하기 위한 메서드 */
      setCustomTypes(key, value) {
        this.searchType.customTypes[key] = value;
      },
      async selectDataList(sort = '-regDt') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'jobId',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }

            params = { ...params, ...this.searchType.paramsData };

            const payload = {
              actionname: 'EUC_TASK_HISTORY_LIST_ALL',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
            }
            return rtnData;
          },
        });
      },
      /** @description : 날짜 형식 변환 메서드 */
      formatDt(date) {
        if (date) {
          return this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        }
      },
      batchType(rowData) {
        if (rowData.battype) {
          const batchType = this.$_getCode('batch_type').find(item => item.codeValue === rowData.battype);
          return batchType?.codeNm || '';
        }
      },
      mountedData() {
        this.selectDataList();
      },
      createdData() {
        this.onChangeSearchDay();
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountedData();
    },
  };
</script>
